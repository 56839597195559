import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Header, Popup, Token } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { cultureValuesRequests, cultureValuesRequestsNew } from '@src/api/cultureValues'
import { navigateTo } from '@src/actions/RouterActions'
import { TableNames } from '@src/constants/table'
import { ROUTES } from '@src/constants/routes'
import {
  valuesBehavioursCountColumn,
  valuesCategoryColumn,
  valuesNameColumn,
  valuesPlaybookLinkColumn,
  valuesStatusColumn,
} from '@src/constants/columns/cultureValues'
import { RowInterface } from '@src/interfaces/data'
import { CultureValueInterface, CultureValueType } from '@src/interfaces/cultureValues'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable, useTableReturnType } from '@components/Table/hooks'
import { pathToUrl } from '@src/utils/router'
import { ValuesForm } from '@src/pages/Forms/CultureValueForm/General'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { ClickableStat } from '@src/pages/Forms/CommonTalentTab/TalentGroupFilters'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'

interface CultureValuesTableProps {
  createRow?: (
    table: useTableReturnType<CultureValueInterface>,
  ) => RowInterface<CultureValueInterface>
  createVariant?: 'popup' | 'navigate'
  filter?: (data: CultureValueInterface[]) => CultureValueInterface[]
  showQuickFilters?: boolean
}

const CultureValuesTable = ({
  createRow,
  createVariant = 'navigate',
  filter,
  showQuickFilters = false,
}: CultureValuesTableProps) => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddCompanyValue)
  const table = useTable<CultureValueInterface>(cultureValuesRequests)
  const [showPopup, setShowPopup] = useState(false)
  const [quickFilter, setQuickFilter] = useState<CultureValueType>()

  const defaultRow: RowInterface<CultureValueInterface> = {
    linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.VALUE.PREVIEW, { id })),
    cells: [
      {
        ...valuesNameColumn,
        width: 380,
      },
      {
        ...valuesCategoryColumn,
        width: 220,
      },
      {
        ...valuesBehavioursCountColumn,
        width: 220,
      },
      {
        ...valuesPlaybookLinkColumn,
        width: 220,
      },
      {
        ...valuesStatusColumn,
        width: 220,
      },
    ],
  }

  const moreActionProps =
    createVariant === 'popup'
      ? { onClick: () => setShowPopup(true) }
      : {
          use: InternalLink,
          to: pathToUrl(ROUTES.FORMS.VALUE.GENERAL),
        }

  const data = filter ? filter(table.data) : table.data

  return (
    <>
      <Table.Widget>
        {showQuickFilters && (
          <Table.Widget.Info>
            <ClickableStat
              onClick={() => setQuickFilter(undefined)}
              label="All values"
              value={data.length}
              higlighted={quickFilter === undefined}
              px="s-16"
              py="s-8"
              borderRadius={Token.radius.r16}
            />
            <ClickableStat
              onClick={() => setQuickFilter(CultureValueType.Culture)}
              label="Cultural values"
              value={
                data.filter(value => value.category === CultureValueType.Culture).length
              }
              higlighted={quickFilter === CultureValueType.Culture}
              px="s-16"
              py="s-8"
              borderRadius={Token.radius.r16}
            />
            <ClickableStat
              onClick={() => setQuickFilter(CultureValueType.Management)}
              label="Management behaviour"
              value={
                data.filter(value => value.category === CultureValueType.Management)
                  .length
              }
              higlighted={quickFilter === CultureValueType.Management}
              px="s-16"
              py="s-8"
              borderRadius={Token.radius.r16}
            />
          </Table.Widget.Info>
        )}
        {canAdd && (
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              <PrimaryAction {...moreActionProps} useIcon={Plus}>
                Create new value
              </PrimaryAction>
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
        )}
        <Table.Widget.Table>
          <AdjustableTable<CultureValueInterface>
            {...table}
            data={data.filter(value =>
              quickFilter ? value.category === quickFilter : true,
            )}
            dataType="Value"
            hideCount
            name={TableNames.CultureValues}
            noDataMessage="Values will appear here."
            row={createRow ? createRow(table) : defaultRow}
            useWindowScroll
          />
        </Table.Widget.Table>
      </Table.Widget>
      {createVariant === 'popup' && (
        <Popup open={showPopup} onClose={() => setShowPopup(false)} size="md">
          <Header displayMode="inline">
            <Header.CloseButton aria-label="close" />
            <Header.Title>Create new value</Header.Title>
          </Header>
          <Form api={cultureValuesRequestsNew}>
            <ValuesForm hidePlaybookInput />
            <Popup.Actions horizontal>
              <Button variant="secondary" onClick={() => setShowPopup(false)}>
                Cancel
              </Button>
              <NewSaveButtonWithPopup
                useValidator
                onAfterSubmit={() => {
                  table.refresh()
                  setShowPopup(false)
                }}
              />
            </Popup.Actions>
          </Form>
        </Popup>
      )}
    </>
  )
}

export default CultureValuesTable
