import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import {
  CulturalSkillInterface,
  SkillInterface,
  SkillSpecialisationInterface,
  SkillsTalentInterface,
  SkillsTalentStatsInterface,
} from '../interfaces/skills'
import {
  ExportRequest,
  GetRequestData,
  RequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '../interfaces'
import { useFetch, useUpdate } from '@src/utils/reactQuery'
import { ApprovalFlowStep } from '@src/interfaces/approvalFlow'
import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import {
  PerformanceRating,
  PerformanceRatingToGraphNumber,
} from '@src/interfaces/performance'
import { getMonthsByRange } from '@src/utils/kpi'
import { CompetencyMatrixInterface } from '@src/interfaces/roles'
import { PerformanceRatingShortTitle } from '@src/constants/performance'

export const skillRequests: RequestInterface<SkillInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.SKILLS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.SKILLS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.SKILLS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.SKILLS}/${id}`),
  postItem: async data => api.post(API.SKILLS, data),
}

export const culturalSkillsRequests: RequestInterface<CulturalSkillInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.SKILLS}/culturalSkills`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.SKILLS}/culturalSkills/${id}`),
  patchItem: async (data, id) => api.patch(`${API.SKILLS}/culturalSkills/${id}`, data),
  deleteItem: async id => api.delete(`${API.SKILLS}/culturalSkills/${id}`),
  postItem: async data => api.post(`${API.SKILLS}/culturalSkills`, data),
}

export const skillRequestsNew: RequestInterfaceNew<SkillInterface> = {
  get: async ({ id }) => api.get(`${API.SKILLS}/${id}`),
  update: async (data, { id }) => apiWithoutHandling.patch(`${API.SKILLS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.SKILLS, data),
  delete: async ({ id }) => api.delete(`${API.SKILLS}/${id}`),
}

export const silentDeleteSkill = async ({ id }: { id: number }) =>
  apiWithoutHandling.delete(`${API.SKILLS}/${id}`)

export const skillSpecialisationsRequests = (
  skillId: number,
): RequestInterface<SkillSpecialisationInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.SKILLS}/${skillId}/specialisations`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.SKILLS}/${skillId}/specialisations/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.SKILLS}/${skillId}/specialisations/${id}`, data),
  deleteItem: async id => api.delete(`${API.SKILLS}/${skillId}/specialisations/${id}`),
  postItem: async data => api.post(`${API.SKILLS}/${skillId}/specialisations`, data),
})

export const skillSpecialisationExport =
  (skillId: number): ExportRequest =>
  (exportType, filterQuery) =>
    api.get(`${API.SKILLS}/${skillId}/specialisations/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    })

export const useGetSkillApprovals = (id?: number) =>
  useFetch<ApprovalFlowStep[]>(id ? `${API.SKILLS}/${id}/approvals` : null)

export const useGetSkillDescription = (id?: number) =>
  useFetch<SkillInterface>(id ? `${API.SKILLS}/${id}` : null)

export const getMatrixSkills = async (): Promise<SkillInterface[]> => {
  const promises = []
  const result = []

  const request = (page: number) =>
    skillRequests.getItems({
      filters: [
        {
          filters: [
            { id: 'functional', name: 'functional' },
            { id: 'executive', name: 'executive' },
            { id: 'culture', name: 'culture' },
          ],
          columnName: 'skill_type',
        },
      ],
      page,
    })

  const initial = await request(1)
  result.push(...initial.data.results)
  const pages = initial.data.pages.total

  if (pages > 1) {
    for (let page = 2; page <= pages; page += 1) {
      promises.push(request(page))
    }
  }

  const responses = await Promise.all(promises)
  responses.forEach(item => result.push(...item.data.results))

  return result
}

export const getCultureSkillAverageRatingGraph = (
  skillId: number,
  range: PerformanceTimeRange = PerformanceTimeRange.quarter,
): AxiosPromise<PerformanceChartCycles> =>
  api
    .get<PerformanceChartCycles<PerformanceRating>>(`/skillRatingHistory`, {
      params: {
        date_partitioning: range,
        months: getMonthsByRange(range),
        skill_id: skillId,
      },
    })
    .then(res => {
      return {
        ...res,
        data: {
          ...res.data,
          targets: [],
          progress_history: res.data.progress_history.map(bar => ({
            ...bar,
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            progress: bar.progress && PerformanceRatingToGraphNumber[bar.progress],
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            estimated: bar.estimated && PerformanceRatingToGraphNumber[bar.estimated],
          })),
        } as PerformanceChartCycles,
      }
    })

export const useGetCultureSkillsCompetencyMatrix = () =>
  useFetch<GetRequestData<CompetencyMatrixInterface>>('/cultureSkillsMatrices')

export const submitCultureSkillsCompetencyMatrix = (
  data: CompetencyMatrixInterface[],
) => {
  return api.post<CompetencyMatrixInterface[]>('/cultureSkillsMatrices', data)
}

export const skillsTalentTableRequests = (
  id: number | string,
): TableRequestInterface<SkillsTalentInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.SKILLS}/${id}/talent`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const useSkillsTalentStats = (id: string) =>
  useFetch<SkillsTalentStatsInterface>(`${API.SKILLS}/${id}/talent/stats`)

export const getSkillsTalentTableExport =
  (id: string): ExportRequest =>
  (exportType, filterQuery) =>
    api.get(`${API.SKILLS}/${id}/talent/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    })

interface TalentDistributionGraphInterface {
  results: {
    rating: PerformanceRating
    percentage: number
  }[]
}

export const getSkillsTalentDistributionGraph = (
  id: string,
): AxiosPromise<PerformanceChartCycles> =>
  api
    .get<TalentDistributionGraphInterface>(`${API.SKILLS}/${id}/talent/distribution`)
    .then(res => ({
      ...res,
      data: {
        progress_history: res.data.results.map(result => ({
          progress: result.percentage,
          progress_datetime_label:
            PerformanceRatingShortTitle[result.rating] || result.rating,
          progress_datetime: PerformanceRatingShortTitle[result.rating] || result.rating,
        })),
        targets: [],
      },
    }))

export const useUpdateSkill = () =>
  useUpdate<SkillInterface, SkillInterface>(
    API.SKILLS,
    undefined,
    undefined,
    false,
    (_oldData, newData) => newData,
    true,
  )
