import { IdAndName, IdAndOptionalName } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { JobPostingLocationInterface } from './jobPosting'
import { ApplicationFormSectionInterface } from './applicationForm'
import { FileInterface } from './files'
import { OptionInterface } from '@src/interfaces/selectors'
import { PhoneOption } from './selectors'
import { InterviewScorecardTemplateSectionInterface } from '@src/interfaces/interviewScorecardTemplates'
import { SpecialisationInterface } from './roles'
import {
  OnboardingCheckpointCategory,
  TenantOnboardingCheckpointInterface,
} from './onboardingChecklist'
import { OnboardingCheckpointCategory as OnboardingCheckpointCategoryV2 } from './onboardingChecklistV2'
import { GradeCalculationMethods } from './reviewCycles'
import { SubscriptionPlanInfterface, SubscriptionState } from './plans'

export type EnabledAndVisibleSetting = {
  enabled: boolean
  visible: boolean
} | null

export type OfficeSuiteProviderType = 'google' | 'microsoft'

export interface CompanySettingsInterface {
  id: number
  company_name: string
  point_of_contact: {
    email: number
    full_name: string
  }
  company_subdomain_key: string
  address_line_1: string
  address_line_2: string
  address_line_3: string
  country?: IdAndName
  county: string
  city: string
  post_code: string
  industry: null
  company_size: null
  subdomain_template: string
  office_suite_provider: IdAndOptionalName<OfficeSuiteProviderType> | null
}

export interface SecuritySettingsInterface {
  id: number
  enable_google_sso: boolean
  enable_password_authentication: boolean
  enable_two_factor_authentication: boolean
}

export type HRAppsToSetup = Extract<
  OnboardingCheckpointCategory,
  'timeOff' | 'documents' | 'contracts' | 'lifecycle' | 'payroll'
>
export type HRAppsToSetupV2 = Extract<
  OnboardingCheckpointCategoryV2,
  'employeeRecords' | 'timeManagement' | 'documents' | 'payroll'
>

export type HRAppsToSetupSettings = HRAppsToSetup | 'selected'
export type HRAppsToSetupSettingsV2 = HRAppsToSetupV2 | 'selected'

export type PerformanceAppsToSetup = Extract<
  OnboardingCheckpointCategory,
  'performance' | 'skills' | 'goals' | 'roadmaps' | 'probation'
>
export type PerformanceAppsToSetupV2 = Extract<
  OnboardingCheckpointCategoryV2,
  'goals' | 'reviews'
>

export type PerformanceAppsToSetupSettings = PerformanceAppsToSetup | 'selected'
export type PerformanceAppsToSetupSettingsV2 = PerformanceAppsToSetupV2 | 'selected'

export type RecruitmentAppsToSetup = Extract<
  OnboardingCheckpointCategory,
  'requisitions' | 'jobPostings' | 'hiring' | 'careers' | 'candidates'
>
export type RecruitmentAppsToSetupV2 = Extract<
  OnboardingCheckpointCategoryV2,
  'jobs' | 'candidates' | 'interviews'
>

export type RecruitmentAppsToSetupSettings = RecruitmentAppsToSetup | 'selected'
export type RecruitmentAppsToSetupSettingsV2 = RecruitmentAppsToSetupV2 | 'selected'

export interface CompanyPreferencesInterface<
  OnboardingVersion extends undefined | 'OnboardingV2' = undefined,
> {
  id: number
  enable_branding: boolean
  brand_name: string
  theme_color: string
  tools: Record<string, boolean>
  other_tools: string[]
  other_tools_text: string | null
  hr_apps_to_setup: Partial<
    Record<
      OnboardingVersion extends 'OnboardingV2'
        ? HRAppsToSetupSettingsV2
        : HRAppsToSetupSettings,
      boolean
    >
  >
  performance_apps_to_setup: Partial<
    Record<
      OnboardingVersion extends 'OnboardingV2'
        ? PerformanceAppsToSetupSettingsV2
        : PerformanceAppsToSetupSettings,
      boolean
    >
  >
  recruitment_apps_to_setup: Partial<
    Record<
      OnboardingVersion extends 'OnboardingV2'
        ? RecruitmentAppsToSetupSettingsV2
        : RecruitmentAppsToSetupSettings,
      boolean
    >
  >
  enable_departments: boolean | null
  enable_multi_specialisations: boolean | null
  enable_functions: boolean | null
}

export interface EmployeeSettingsInterface {
  id: number
  employee_labels?: { id?: number; name: string }[] | null
  allowed_email_domains: string[] | null
  enable_request_changes_organisation: boolean
  enable_request_changes_position: boolean
  enable_request_changes_name: boolean
  enable_import_data: boolean
  enable_export_csv_xls: boolean
  enable_show_employee_id: boolean
  enable_buddy: boolean
  enable_job_title: boolean
  enable_notes: boolean
  enable_bank_details: boolean
  enable_dynamic_groups_badges: boolean
  enable_custom_fields: boolean
  custom_fields_allowed_sections: IdAndName[]
  enable_department_migration: boolean
}

export interface GroupSettingsInterface {
  enable_dynamic_groups_sql: boolean
  dynamic_groups_allowed_sql_clients: IdAndName[]
}

export type UseGetEmployeeSettingsInterface = EmployeeSettingsInterface &
  GroupSettingsInterface

export type AccountStatus =
  | 'pending'
  | 'preparing'
  | 'setup_failed'
  | 'active'
  | 'suspended'
  | 'closed'
  | 'waiting_list'
  | 'demo'

interface AccountAdmin {
  id: string | number
  first_name: string
  middle_name: string | null
  last_name: string
  phone_country_code: PhoneOption
  phone_number_short: string
  email: string
  /** @deprecated */
  phone_number: string | null
}

type LatestSubscriptionInvoiceStatusType = 'open' | 'paid'

// TODO: https://revolut.atlassian.net/browse/REVCOR-2566 should be an object
export type LatestSubscriptionInvoiceStatus =
  | IdAndName<LatestSubscriptionInvoiceStatusType>
  | LatestSubscriptionInvoiceStatusType
  | null

export interface AccountsSettingsInterface {
  id: number | string
  company_name: string
  subdomain: string
  db_name: string
  admin: AccountAdmin
  state: AccountStatus
  country: IdAndName | null
  active_headcount: number
  is_payment_method_set: boolean
  latest_subscription_invoice_status?: LatestSubscriptionInvoiceStatus
  labels: string[]
  features: IdAndName<string>[]
  onboarding_stats?: TenantOnboardingCheckpointInterface[]
  vat_number: string | null
  vat_number_verified_date_time: string | null
  subscription_state: IdAndName<SubscriptionState>
  custom_plan_requested_at: string | null
  subscription_plan: SubscriptionPlanInfterface | null
  demo_mode_expiration_date_time: string | null
}

export interface DocumentsSettingsInterface {
  id: number
  enable_docusign_integration: boolean
  enable_document_generation_from_templates: boolean
  enable_document_requests: boolean
}

export type SkillSettingsInterface = {
  id: number
  behaviours_assessment_enabled: boolean
  enabled: boolean
  skills_approvals_enabled: boolean
  company_values_validation_enabled: boolean
} & Partial<Pick<ApprovalProcessSteps, 'skills_approvals'>>

export type OrganisationSettingsInterface = {
  id: number
  customer_experience: EnabledAndVisibleSetting
  bugs_tracking: EnabledAndVisibleSetting
  findings: EnabledAndVisibleSetting
  risk: EnabledAndVisibleSetting
  data_access_requests: EnabledAndVisibleSetting
  enable_team_owners_view_salaries: boolean
  enable_teams_approvals: boolean
  enable_leadership: boolean
  enable_budget_management: boolean
  karma: EnabledAndVisibleSetting
  enable_departments_approvals: boolean
  enable_role_specialisation_owners_view_salaries: boolean
  enable_roles_approvals: boolean
  enable_functions_approvals: boolean
  enable_specialisations_approvals: boolean
  enable_download_actions_for_department_data: boolean
  enable_download_actions_for_function_data: boolean
  enable_multiple_levels_per_seniority: boolean
  enable_location_limitations: boolean
  enable_executive_functions: boolean
  enable_cost_control_assignment: boolean
  /** The following props are used when submitting, to order approval steps */
} & Partial<
  Pick<
    ApprovalProcessSteps,
    | 'teams_approval_steps'
    | 'departments_approval_steps'
    | 'roles_approval_steps'
    | 'specialisations_approval_steps'
  >
>

export type ContractsSettingsInterface = {
  id: number
  secondary_contracts_enabled: boolean
  change_justification_enabled: boolean
  documents_enabled: boolean
  link_to_justification_enabled: boolean
  force_termination_enabled: boolean
}

export interface RoadmapSettingsInterface {
  id: number
  enabled: boolean
  jira_epics_enabled: boolean
}

export interface TodoSettingsInterface {
  id: number
}

export interface LocationSettingsInterface {
  id: number
  enable_location_hr_fields: boolean
  location_labels?: string[] | null
}

export type AccessManagementSettingsInterface = {
  id: number
  enable_limited_time_access: boolean
  enable_service_accounts: boolean
  enable_automatic_group_assignment: boolean
  enable_sql_group_assignment: boolean
  enable_group_access_request_approvals: boolean
} & Partial<Pick<ApprovalProcessSteps, 'group_access_request_approvals'>>

export type CYCLE_DURATION = 'quarterly' | 'biannually' | 'annually'

export type ReviewCycleTimelineField =
  | 'start_date_time'
  | 'end_date_time'
  | 'review_period_start_day'
  | 'managers_reviews_last_day'
  | 'reviews_publishing_day'
  | 'managers_publishing_day'
  | 'self_and_peer_reviews_last_day'
  | 'department_owner_calibration_start_day'
  | 'head_of_function_calibration_start_day'
  | 'head_of_function_and_department_last_calibration_day'
  | 'department_kpi_period_start_day'
  | 'department_kpi_period_end_day'
  | 'team_kpi_period_start_day'
  | 'team_kpi_period_end_day'
  | 'individual_kpi_period_start_day'
  | 'individual_kpi_period_end_day'
  | 'end_cycle_kpi_freeze_day'
  | 'individual_kpi_setting_deadline_day'
  | 'roadmap_start_date'
  | 'roadmap_end_date'

export interface Timeline {
  id: number
  field: ReviewCycleTimelineField
  base_field: ReviewCycleTimelineField
  days: number
  closest_week_day?: unknown | null
  time?: unknown | null
}

export type PerformanceSettingsInterface = {
  id: number
  enable_auto_reviewed_employee_type: boolean
  enable_segmented_deliverables_assessment: boolean
  enable_limit_allowed_complexity_rating: boolean
  default_reviewed_employee_type:
    | 'default_reviewed_employee_type'
    | 'individual_contributor'
    | 'personal_kpi_individual'
  enable_functional_management: boolean
  enable_kpi_templates: boolean
  kpi_measuring_units: string[]
  allowed_kpi_strategies: IdAndName[]
  allowed_kpi_types: IdAndName<'sql' | 'manual' | 'roadmap' | 'cascaded' | 'template'>[]
  enable_function_role_specialisation_kpis: boolean
  enable_employee_kpi_approvals: boolean
  enable_team_kpi_approvals: boolean
  enable_department_kpi_approvals: boolean
  enable_company_kpi_approvals: boolean
  enable_function_kpi_approvals: boolean
  enable_role_kpi_approvals: boolean
  enable_specialisation_kpi_approvals: boolean
  enable_self_reviews: boolean
  enable_upwards_reviews: boolean
  enable_peer_reviews: boolean
  enable_pip: boolean
  enable_probation: boolean
  enable_probation_and_pip_goals_via_jira: boolean
  enable_multiple_goal_targets_per_cycle: boolean
  enforce_pip_committee_review: boolean
  enforce_probation_committee_review: boolean
  probation_manager_employees: PerformanceSettingsManagers[]
  probation_manager_groups: PerformanceSettingsManagers[]
  pip_manager_employees: PerformanceSettingsManagers[]
  pip_manager_groups: PerformanceSettingsManagers[]
  custom_scorecard_sections?: PerformanceScorecardSectionInterface[]
  enable_values: boolean
  enable_skill_assessment: boolean
  enable_mandatory_parent_kpi: boolean
  enable_calibration: boolean
  enable_kpi_setting_dates: boolean
  publish_grades_to_managers_before_employees: boolean
  enable_custom_goal_timeline: boolean
  enable_custom_calibration_timeline: boolean
  are_all_employees_eligible_for_review: boolean
  cycle_duration: IdAndName<CYCLE_DURATION, string>
  enable_custom_scorecard_sections: boolean
  grade_calculation_method: GradeCalculationMethods
  timelines: Timeline[]
  /** The following props are used when submitting, to order approval steps */
} & Partial<
  Pick<
    ApprovalProcessSteps,
    | 'company_kpis_approvals'
    | 'department_kpis_approvals'
    | 'team_kpis_approvals'
    | 'employee_kpis_approvals'
    | 'function_kpis_approvals'
    | 'role_kpis_approvals'
    | 'teams_approval_steps'
  >
>

type ApproverType = 'relationship' | 'employee' | 'group'

export type ApproverRalationship =
  | 'team_approval_relationships'
  | 'role_approval_relationships'
  | 'specialisation_approval_relationships'
  | 'employee_kpi_approval_relationships'
  | 'role_kpi_approval_relationships'
  | 'team_kpi_approval_relationships'

export type ApprovalProcess =
  | 'teams_approval_steps'
  | 'departments_approval_steps'
  | 'roles_approval_steps'
  | 'specialisations_approval_steps'
  | 'company_kpis_approvals'
  | 'department_kpis_approvals'
  | 'team_kpis_approvals'
  | 'employee_kpis_approvals'
  | 'function_kpis_approvals'
  | 'role_kpis_approvals'
  | 'skills_approvals'
  | 'requisition_approvals'
  | 'job_posting_approvals'
  | 'group_access_request_approvals'
  | 'employee_attendance_tracking_overtime_approvals'
  | 'offer_approvals'
  | 'benchmarks_approvals'

export interface ApprovalFlowSettingStep {
  id: number
  name: string
  approval_process: ApprovalProcess
  approver_type: IdAndName<ApproverType>
  relationship?: IdAndName<ApproverRalationship>
  employee_approver?: EmployeeOptionInterface
  group_approver?: IdAndName
  order: number
}

export interface ApprovalProcessSteps {
  teams_approval_steps: ApprovalFlowSettingStep[]
  departments_approval_steps: ApprovalFlowSettingStep[]
  roles_approval_steps: ApprovalFlowSettingStep[]
  specialisations_approval_steps: ApprovalFlowSettingStep[]
  company_kpis_approvals: ApprovalFlowSettingStep[]
  department_kpis_approvals: ApprovalFlowSettingStep[]
  team_kpis_approvals: ApprovalFlowSettingStep[]
  employee_kpis_approvals: ApprovalFlowSettingStep[]
  function_kpis_approvals: ApprovalFlowSettingStep[]
  role_kpis_approvals: ApprovalFlowSettingStep[]
  skills_approvals: ApprovalFlowSettingStep[]
  requisition_approvals: ApprovalFlowSettingStep[]
  job_posting_approvals: ApprovalFlowSettingStep[]
  group_access_request_approvals: ApprovalFlowSettingStep[]
  employee_attendance_tracking_overtime_approvals: ApprovalFlowSettingStep[]
  offer_approvals: ApprovalFlowSettingStep[]
  benchmarks_approvals: ApprovalFlowSettingStep[]
}

export type PerformanceScorecardQuestionTypeOptions =
  | 'checkbox'
  | 'option'
  | 'text'
  | 'textarea'
  | 'dropdown'

export type PerformanceScorecardQuestionType =
  IdAndName<PerformanceScorecardQuestionTypeOptions>

export type PerformanceScorecardOption = { id?: number; text: string }

export interface PerformanceScorecardQuestionInterface {
  id?: number
  title?: string
  question_type: PerformanceScorecardQuestionType | null
  options?: PerformanceScorecardOption[]
  optional?: boolean
  placeholder_text?: string
  subtitle?: string
}

export interface PerformanceScorecardSectionInterface {
  id?: number
  title?: string
  description: string | null
  show_for: IdAndName[] | null
  questions: PerformanceScorecardQuestionInterface[]
}

export interface GlobalSettingsResponse {
  attendance: { enabled: boolean }
  compensation: { enabled: boolean }
  benefits: { enabled: boolean }
  engagement: { enabled: boolean }
  promotions: { enabled: boolean }
  feedback: { enabled: boolean }
  key_person: { enabled: boolean; visible?: boolean }
  linked_accounts: { enabled: boolean; visible?: boolean }
  screening: { enabled: boolean }
  documents: {
    enable_document_generation_from_templates: boolean
    enable_docusign_integration: boolean
  }
  requisitions: { enabled: boolean }
  candidates: { enabled: boolean }
  job_postings: { enabled: boolean }
  commercial_product: { enabled: boolean }
  resignations: { enabled: boolean }
  payroll: { enabled: boolean }
  career_page: { enabled: boolean }
  applicant_tracking: { enabled: boolean }
  referrals: { enabled: boolean }
  recruitment_groups: { enabled: boolean }
  candidate_scheduling: { enabled: boolean }
  offers: { enabled: boolean; enable_offer_templates: boolean }
  total_compensation: { enabled: boolean }
  probation: { enabled: boolean }
  pip: { enabled: boolean }
  employee_onboarding_v2: { enabled: boolean }
}

export interface GlobalPublicSettingsResponse
  extends Pick<GlobalSettingsResponse, 'commercial_product'> {}

export interface CommunicationSettingsInterface {
  id: number
  enable_automatic_slack_channel_and_group_creation: boolean
  enable_email_notifications: boolean
  enable_slack_notifications: boolean
  enable_system_notifications: boolean
  enable_announcements: boolean
  enable_sms_notifications: boolean
  enable_sql_audience_selection_type: boolean
  enable_event_based_delivery_type: boolean
}

export interface TimeOffSettingsInterface {
  id: number
  enabled: boolean
  eligibility: IdAndName[]
  policy_categories: IdAndName[]
}

export interface ContactHRSettingsInterface {
  contact_hr_email: string | null
  contact_hr_name: string | null
}

export interface LifecycleSettingsInterface extends ContactHRSettingsInterface {
  id: number
  activation_email: string | null
  enable_access_retention: boolean
  enable_diversity: boolean
  enable_email_selector: boolean
  enable_equipment_delivery: boolean
  enable_offboarding_tickets_task: boolean
  enable_right_to_work_tab: boolean
  enable_screening_tab: boolean
  enable_sla_tracking: boolean
  external_email_domain: string | null
  internal_email_domain: string | null
  magic_link_email: string | null
  welcome_page_text: string | null
  welcome_page_title: string | null
  welcome_page_video_url: string | null
  enable_onboarding_landing_page: boolean
  enable_external_employees_onboarding: boolean
}

export interface EmployeeOnboardingSettingsInterface {
  id: number
  contact_hr_email: string | null
  contact_hr_display_name: string | null
  collect_diversity_information: boolean
  collect_equipment_shipment_information: boolean
  collect_rights_to_work_information: boolean
  collect_screening_information: boolean
  enable_email_selector: boolean
  enable_external_employees_onboarding: boolean
  external_email_domain: string | null
  internal_email_domain: string | null
  invitation_email_body: string | null
  invitation_email_subject: string | null
  redirect_url_on_completion: string | null
  welcome_page_html: string | null
  welcome_page_title: string | null
  welcome_page_video_url: string | null
}

export type RequisitionSettingsInterface = {
  enabled: boolean
  enable_confidential_button: boolean
  enable_budget_impact: boolean
  enable_table_hiring_fields: boolean
  enable_hiring_progress: boolean
  enable_hiring_process: boolean
  enable_hired_headcount_editing: boolean
  enable_candidate_tab: boolean
  enable_location_validation: boolean
  enable_apply_button: boolean
  enable_approvals: boolean
  enable_is_regulated_editing: boolean
  enable_is_mrt_jira_ticket_url_confirmed_editing: boolean
  enable_minimum_potential_start_date_validation: boolean
} & Partial<Pick<ApprovalProcessSteps, 'requisition_approvals'>>

export interface ScreeningSettingsInterface {
  id: number
  enabled: boolean
  manual_check_enabled: boolean
  automatic_check_enabled: boolean
}

export type JobPostingSettingsInterface = {
  id?: number
  enable_applications_tracking: boolean
  enable_automatic_compensation_ranges: boolean
  enable_automation_rules: boolean
  enable_careers_website: boolean
  enable_internal_job_board: boolean
  enable_requisition_publishing_checks: boolean
  enabled: boolean
  enable_approvals: boolean
  mandatory_compensation_locations: JobPostingLocationInterface[]
  enable_all_publishing_statuses: boolean
  linkedin_company_id?: number
  linkedin_contract_id?: number
  linkedin_industry_code?: OptionInterface
  enable_linkedin_integration: boolean
  enable_monster_integration: boolean
  job_posting_sections: { title: string }[]
} & Partial<Pick<ApprovalProcessSteps, 'job_posting_approvals'>>

export type ApplicationFormSettingsInterface = {
  id?: number
  enable_phone_number_field: boolean
  is_phone_number_optional: boolean
  enable_current_company_field: boolean
  is_current_company_optional: boolean
  enable_preferred_work_location_field: boolean
  is_preferred_work_location_optional: boolean
  enable_resume_field: boolean
  is_resume_field_optional: boolean
  enable_portfolio_link_field: boolean
  is_portfolio_link_optional: boolean
  global_sections: ApplicationFormSectionInterface[]
  enable_education_field: boolean
  is_education_optional: boolean
  enable_work_experience_field: boolean
  is_work_experience_optional: boolean
}

export interface PayrollSettingsInterface {
  id: number
  enabled: boolean
}

export type OfferSettingsInterface = {
  id?: number
  enable_offer_templates: boolean
  enable_approvals: boolean
  enable_offer_signing: boolean
} & Partial<Pick<ApprovalProcessSteps, 'offer_approvals'>>

export interface HelpCenterSettingsInterface {
  id: number
  help_section_enabled: boolean
  chatbot_enabled: boolean
}

export type ExternalLink = {
  id: number
  label: string
  url: string
}

export type CompanyLogo = Pick<FileInterface, 'id' | 'name' | 'url'>

export type FunctionGroup = {
  id: number
  name: string
  functions: IdAndName[]
}

export type CareerPageSettingsInterface = {
  id?: number
  enabled: boolean
  publish_career_website: boolean
  data_privacy_notice_url?: string
  slogan: string
  company_logo?: CompanyLogo | null
  company_links: ExternalLink[]
  about_the_company: string
  group_by_function: boolean
  function_groups: FunctionGroup[]
}

export type CandidateSettingsInterface = {
  enabled: boolean
  enable_scheduling: boolean
  enable_emails: boolean
  enable_candidate_tab: boolean
  enable_ats: boolean
  no_reply_email: string
  no_reply_name: string
  freezing_period_months: number
  readonly enable_referral: boolean
  readonly enable_email_suite: boolean
  enable_candidate_no_reply: boolean
  default_automatic_scheduling_email_template?: OptionInterface
  default_custom_scheduling_email_template?: OptionInterface
  default_adhoc_call_email_template?: OptionInterface
}

export type KeyPersonsSettingsInterface = {
  id: number
  succession_minimal_successors_count: number
  succession_plan_renewal_period: number
  succession_plan_renewal_period_unit: IdAndName<string>
}

export type HiringProcessSettingsInterface = {
  readonly enabled: boolean
  readonly enable_hiring_panel_stage: boolean
  readonly enable_online_test_stage: boolean
  readonly enable_role_level_process_definition: boolean
  enable_recruitment_groups: boolean
  default_lead_recruiter?: EmployeeOptionInterface
  default_recruitment_group?: OptionInterface
  global_sections?: InterviewScorecardTemplateSectionInterface[]
  is_compensation_optional: boolean
  is_location_optional: boolean
  enable_compensation_collecting: boolean
  enable_location_collecting: boolean
  enable_right_to_work_collecting: boolean
  is_right_to_work_optional: boolean
}

export enum PerformanceSettingsManagersEntityType {
  Employee = 'Employee',
  Group = 'Group',
}

export interface PerformanceSettingsManagers {
  id: number
  name?: string
  full_name?: string
  avatar?: string | null
}

export interface PerformanceSettingsManagersWithType extends PerformanceSettingsManagers {
  type: PerformanceSettingsManagersEntityType
}

export interface CountryHiringInterface {
  id: number
  country: {
    name: string
    employment_legal_risk: string
    locations: {
      id: number
      location_name: string
      name: string
    }[]
  }
  employment_legal_risk: string
  preferred_specialisations?: Partial<SpecialisationInterface>[]
  hiring_enabled: boolean
  all_specialisations_preferred: boolean
}

export type AttendanceTrackingSettings = {
  id: number
  enable_employee_attendance_tracking_approvals: boolean
  restrict_employee_overtime_requests: boolean
  creation_date_time: string
  update_date_time: string
} & Pick<ApprovalProcessSteps, 'employee_attendance_tracking_overtime_approvals'>

export type CompensationSettings = {
  id: number
  enabled: boolean
  benchmarks_approval_enabled: boolean
  bonus_categories: string[]
} & Pick<ApprovalProcessSteps, 'benchmarks_approvals'>

export interface LinkedinIntegrationResponseInterface {
  integrationContext: string
  integrationType: 'PREMIUM_JOB_POSTING'
  onboardingStatus: 'REQUESTED' | 'COMPLETED'
  tenantType: 'JOBS'
}

export interface DeelIntegrationPreferencesInterface {
  id: number
  enabled: boolean
}

export interface DeelIntegrationResponseInterface {
  oauth_url: string
}
